
<template>
    <div class=" ">
        <b-container>
            <b-row class="footerMenuBlock classAddedtwo">
                <b-col lg="7" md="12" cols="12" class="WholeFooterLinks">           
                    <div class="footerOneSection">
                      <b-link :to="'/schedule?id=223S'">
                        <span class="footerTitle">Schedule</span>
                      </b-link>
                    </div>
                    <div class="footerOneSection">
                      <b-link :to="'/players?id=223S'">
                        <span class="footerTitle">Players</span>
                      </b-link>
                    </div>
                    <div class="footerOneSection">
                      <b-link :to="'/news'">
                        <span class="footerTitle">News</span>
                      </b-link>
                    </div>
                    <div class="footerTwoSection">
                      <b-link :to="'oom?id=223S'">
                        <span class="footerTitle">Stats</span>
                      </b-link>
                    </div>
                </b-col>
                <b-col lg="5" md="12" cols="12">
                <div class="SocailBlock">
                    <div class="socialLogoDiv">
                        <a href="https://www.facebook.com/SunshineTourGolf" target="_blank">
                            <img class="socialLogosFooter" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Facebook_White.png">
                        </a>
                    </div>
                    <div class="socialLogoDiv">
                        <a href="https://www.instagram.com/sunshinetourgolf/" target="_blank">
                            <img class="socialLogosFooter" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/Instagram_White.png">
                        </a>                    
                    </div>
                    <div class="socialLogoDiv">
                        <a href="https://www.youtube.com/user/TheSunshineTour" target="_blank">
                            <img class="socialLogosFooter" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/YouTube_White.png">
                        </a>
                    </div>
                    <div class="socialLogoDiv">
                        <a href="https://twitter.com/Sunshine_Tour" target="_blank">
                            <img class="socialLogosFooter lastSocialIcon" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2023/11/Twitter_White.png">
                        </a>
                    </div>
                </div>
                <div class="downloadBlock">
                    <p class="downloadText">Download the official Sunshine Tour app for all the latest news, stats, player profiles and exclusive content</p>
                </div>
                <div class="groupAppStore">
                    <div class="appleStoreLogo">
                        <a href="https://apps.apple.com/gb/app/sunshine-tour/id419098478" target="_blank">
                            <img class="appStore" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/AppStore_White.png">
                        </a>
                    </div>
                    <div>
                        <a href="https://play.google.com/store/apps/details?id=com.sunshinetour.sunshinetour&hl=en_GB&gl=US" target="_blank">
                            <img class="appStore" src="https://updated.sunshinetour.com/wp-content/uploads/2022/07/GooglePlay_White.png">
                        </a>
                    </div>
                </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  props: ["course", "currentReport", "title", "season", "config"],
  data() {
    return {

    };
  },
};
</script>

<style scoped>
.menuPadFooter > a {
  text-decoration: none!important;
}
span.footerTitle:hover {
  color: #c7c7c7;
  text-decoration: none!important;
}
.downloadBlock {
  margin-top: 60px;
  margin-bottom: 20px;
}
.menuPadFooter {
  padding-bottom: 10px;
}
.footerMenuBlock {
  padding-top: 60px;
  padding-bottom: 60px;
}
.WholeFooterLinks {
    /* display: inline-flex; */
}
.footerOneSection {
  padding-right: 50px;
}
.footerTwoSection {
  padding-right: 50px;
}
span.footerTitle {
  color: #fff;
  text-align: left;
  text-transform: capitalize;
  font-size: 13px;
}
.lastSocialIcon {
  margin-right: 0px!important;
}
.socialLogosFooter {
  margin-right: 10px;
}
.SocailBlock {
  text-align: right;
}
.socialLogoDiv {
  display: inline-block;
}
.socialLogosFooter {
  width: 22px;
  height: 22px;
}
.downloadText {
  color: #fff;
  text-align: right;
  font-size: 10px;
  width: 280px;
  float: right;
  margin-bottom: 30px;
}
.appleStoreLogo {
  margin-right: 10px;
}
.groupAppStore {
  display: inline-flex;
  float: right;
}
.appStore {
  width: 120px;
}
.SecondBottomSection {
  background-color: #000; 
}
.footerLogo {
  width: 100px;
  margin: auto;
  display: block;
}
.bigEasyback {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/Easy_BG.png);
  padding-top: 10px;
  padding-bottom: 10px;
}
.ladiesTourback {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/LAdies_BG.png);
  padding-top: 10px;
  padding-bottom: 10px;
}
.seniorback {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/Senior_BG.png);
  padding-top: 10px;
  padding-bottom: 10px;
}
.footerAds {
  /* margin-bottom: 17.5em; */
}
.ocsLogo {
  width: 60px;
  margin-top: 15px;
  float: right;
}
.mediaOCS {
  float: right;
}
.socialIcons-pad {
  margin-top: 25px!important;
}
img.Social-logos {
  padding-right: 10px;
}
img.socialPad-icons {
  padding-right: 10px;
}
ul.sst-socal-links.d-table.float-md-right {
  position: relative;
  top: 25px;
}
li.socialIcons {
  display: contents;
}
ul.downloadTabs {
  padding-left: 0;
  margin-top: 25px;
}
li.downloadLi {
  display: table-cell;
  padding-right: 15px;
}
img.downloadAPP{
  width: 135px;
  height: 40px;
}
.connectUs {
  margin-right: 3em;
}
.socailIcon {
  font-size: 18px !important;
  color: #fff;
  margin-right: 25px;
}
.connectRight {
  float: right;
  color: #fff;
  margin-top: 7px;
  font-size: 12pt;
}
.bottomMenu {
  position: relative;
  top: 0px;
}
.footerPad-Download {
  padding-bottom: 20px;
}
span.footerSocial {
  display: contents;
  float: right;
}
.indiFooter-social {
  width: 20px;
  max-width: 45px;
}
::v-deep .footLink > a {
  font-size: 9pt;
  padding-left: 0;
}
span.premierPartners {
  padding: 1rem 0 3rem;
}

#insta {
  border-bottom: 1px solid #868686;
  padding: 20px 0;
}
.partners {
  text-align: left;
  display: block;
  color: #000;
  padding-top: 20px;
  font-size: 24px;
  padding-bottom: 0.3em;
  line-height: 1.25;
}
.third > .col:last-child > a > .logoImg {
  width: 75px;
}
.row.third.logoRow {
  margin-top: 2em;
}
.logoImg {
  /* width: 100%; */
}
.logoImgTwo {
  width: 115px;
}
.logoImgThree {
  width: 100px;
}
.logoImgFour {
  width: 90px;
}
.green {
  background-color: #000 !important;
  /* padding-top: 20px; */
}
.lightGreen {
  background-color: #fff;
  padding: 30px 0;
}
.logoRow {
  padding-top: 0%;
  padding-bottom: 1%;
  text-align: center;
  margin-right: 0;
}
.copyRight {
  color: #fff;
  font-size: 85%;
  line-height: 5;
}
.media-body {
  flex: inherit;
  padding-top: 15px;
}
.bottomRight {
  font-size: 9pt;
}
.bottomRight > a {
  color: #fff !important;
}
.bottomRight > a:hover {
  color: #000 !important;
}
.ocs {
  /* padding-bottom: 22px;
  padding-top: 6px; */
}
.appImg {
  width: 130px;
  margin-top: 10px;
}
.download {
  color: #fff;
  font-weight: 600;
  font-size: 9pt;
  margin-top: 32px;
  width: 125px;
}
.connect {
  float: right;
  color: #fff;
  margin-top: 11px;
  font-size: 9pt;
}
li {
  list-style-type: none;
}
li > a {
  color: white !important;
}
.menuLink > a {
  font-size: 9pt;
}
.navbar {
  padding: 0.5rem 0;
}

@media only screen and (max-width: 990px) {
    .groupAppStore {
        display: inline-flex;
        float: left;
    }
    .downloadText {
        color: #fff;
        text-align: left;
        font-size: 10px;
    }
    .SocailBlock {
        text-align: left;
    }
}
@media only screen and (max-width: 768px) {
    .connect {
        float: left;
    }
    .icon {
        font-size: 26px;
    }
    .mobHide {
        display: none;
    }
}
</style>
