<template>
  <div style="overflow:hidden;">
    <div id="jumbo"></div>
    <b-jumbotron
      class="backgroundImage"
      v-bind:style="{
        backgroundImage:
          'url(' +
          DEFIMAGESAMS3 +
          'sst' +
          '/' +
          'courses' +
          '/' +
          season +
          '/' +
          tm.course_code +
          '/' +
          tm.course_image_file_1 +
          ')'
      }"
    >
      <b-container>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="6">
            <div class="textBlock">
              <p class="dates">{{ tm.dates }}</p>
              <h1 class="shortname">{{ tm.short_name }}</h1>
              <p class="coursename">{{ tm.course_name }}</p>
              <b-link
                :to="{
                  name: 'tournament',
                  query: {
                    id: season,
                    code: tm.code
                  }
                }"
                class="tourInfoBtn"
              >
                <span class="tournInfo">
                  Tournament Information
                </span>
              </b-link>
            </div>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="6">
            <div class="leaderboardZindex">
              <template v-if="multiTourns == 'Y'">
                <b-row
                  class="pastbtnRow"
                  v-if="pastTourns.length > 0 || futureTourns.length > 0"
                >
                  <b-col
                    class="tabBtn"
                    col="12"
                    lg="4"
                    v-for="tourn in pastTourns"
                    :key="tourn.code"
                  >
                    <b-button
                      class="pastbtn"
                      @click="swapTourn(tourn.code, tourn.name)"
                      :class="{
                        activeBtn: pastT === tourn.code
                      }"
                      >{{ tourn.name }}</b-button
                    >
                  </b-col>
                  <b-col col="12" lg="4">
                    <b-button
                      class="pastbtn"
                      @click="swapTourn(tm.code, tm.full_name)"
                      :class="{ activeBtn: pastT === tm.code }"
                      >{{ tm.full_name }}</b-button
                    >
                  </b-col>
                  <b-col
                    class="tabBtn"
                    col="12"
                    lg="4"
                    v-for="tourn in futureTourns"
                    :key="tourn.code"
                  >
                  </b-col>
                </b-row>
                <b-row class="pastbtnRow">
                  <b-col
                    class="tabBtn"
                    col="12"
                    lg="4"
                    v-if="tm.past_tourns !== ''"
                  >
                    <b-link
                      :to="{
                        name: 'tournament',
                        query: {
                          id: this.season,
                          code: tm.past_tourns.past_tourns_entry.code
                        }
                      }"
                    >
                      <b-button
                        class="pastbtn"
                        @click="
                          (pastT = tm.past_tourns.past_tourns_entry.code),
                            (head = tm.past_tourns.past_tourns_entry.name)
                        "
                        :class="{
                          activeBtn:
                            pastT === tm.past_tourns.past_tourns_entry.code
                        }"
                        >Blue Label Teams Challenge</b-button
                      >
                    </b-link>
                  </b-col>
                  <b-col col="12" lg="4">
                    <b-link
                      :to="{
                        name: 'tournament',
                        query: {
                          id: this.season,
                          code: tm.code
                        }
                      }"
                    >
                      <b-button
                        class="pastbtn"
                        @click="(pastT = tm.code), (head = tm.full_name)"
                        :class="{ activeBtn: pastT === tm.code }"
                        >{{ tm.full_name }}</b-button
                      >
                    </b-link>
                  </b-col>
                  <b-col
                    class="tabBtn"
                    col="12"
                    lg="4"
                    v-if="tm.future_tourns !== ''"
                  >
                    <b-link
                      :to="{
                        name: 'tournament',
                        query: {
                          id: this.season,
                          code: tm.future_tourns.future_tourns_entry.code
                        }
                      }"
                    >
                      <b-button
                        class="pastbtn"
                        @click="
                          (pastT = tm.future_tourns.future_tourns_entry.code),
                            (head = tm.future_tourns.future_tourns_entry.name)
                        "
                        :class="{
                          activeBtn:
                            pastT === tm.future_tourns.future_tourns_entry.code
                        }"
                        >{{
                          tm.future_tourns.future_tourns_entry.name
                        }}3</b-button
                      >
                    </b-link>
                  </b-col>
                </b-row>
              </template>
              <b-tabs align="right">
                <div class="report">
                  <MiniLeader
                    :course="pastT"

                    :season="season"
                    :report="pastTournreport"
                    :title="title"
                    :config="config"
                    :tmparamsMini="tmparamsMini"
                    :apiClass="apiClass"
                    :multiTourns="multiTourns"
                    :mutliData="mutliData"
                  />
                </div>
                <!-- <b-tab title="ORDER OF MERIT">
                  <div class="report Oom">
                    <Oom 
                    :Year="tm.tm_params.season_code" 
                    :config="config"/>
                  </div>
                </b-tab> -->
              </b-tabs>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-jumbotron>
  </div>
</template>

<script>
// import apiCall from "@/services/api.js";
import MiniLeader from "@/components/miniLeader.vue";
export default {
  name: "jumbo",
  props: [
    "season",
    "course",
    "currentReport",
    "title",
    "config",
    "tm",
    "pastTourns",
    "futureTourns",
    "pastT",
    "head",
    "tmparamsMini",
    "DEFIMAGESAMS3",
    "apiClass",
    "multiTourns",
    "mutliData"
  ],
  components: {
    MiniLeader
  },
  data() {
    return {
      // tm: [],
      // tm_params: [],
      // id: [],
      // data: [],
      // pastT: [],
      // wpHome:[],
    };
  },
  created() {
    console.log(this.pastTourns);
  },
  computed: {
    FutureEventFilter: function() {
      return this.data.filter(data => data.days_away >= 0);
    },
    slidesTotal: function() {
      return this.FutureEventFilter.length;
    },
    pastTournreport: function(r) {
      if (this.tm.past_tourns === "") {
        r = this.currentReport;
      } else {
        if (this.title.includes("Final")) {
          r = this.currentReport;
        } else {
          // r = "http://sunshinetour.info/tic/tmlsmon.cgi?tourn=" + this.pastT + "~season=" + this.season + "~" \
          r = this.currentReport;
        }
      }
      return r;
    }
  },
  methods: {
    scrollToElement() {
      let content = document.querySelector(".outer_container");
      content.scrollLeft = 1600;
    },
    swapTourn(code, name) {
      this.pastT = code;
      this.head = name;
      // this.updateStuff();
      // this.getSecondTournTicx(tournCode);
    }
  },
  async mounted() {
    setTimeout(() => {
      this.scrollToElement();
    }, 1000);
  }
};
</script>

<style scoped>
.tourInfoBtn {
  text-align: center;
  display: block;
  /* margin: auto; */
  background: none;
  border: none;
  color: #fff !important;
  background-color: #000;
  border-radius: 0;
  padding: 10px 20px;
  width: 300px;
  font-size: 9pt;
  font-weight: bold;
  height: 35px;
  line-height: 1.1;
}
.tourInfoBtn:hover {
  text-align: center;
  display: block;
  /* margin: auto; */
  background: none;
  border: none;
  color: #fff !important;
  background-color: #2c2c2c;
  border-radius: 0;
  padding: 10px 20px;
  width: 300px;
  font-weight: bold;
  text-decoration: none;
  height: 35px;
  line-height: 1.1;
}
::v-deep .tabsLi > div > div > ul {
  display: inline-grid;
  width: 50%;
  text-align: center;
}
::v-deep .FullListingBtn > a {
  color: #fff !important;
  text-decoration: none;
  font-size: 13px;
  padding-left: 0px;
}
::v-deep .catD {
  background-color: #000;
  color: #ffffff;
  font-size: 10pt;
  font-weight: 600;
}
::v-deep td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0px solid #6c6c6c;
  border-right: none !important;
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  color: #6c6c6c;
}
::v-deep th {
  position: sticky;
  top: 0;
  background-color: #000;
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
  font-weight: 100;
  color: white;
  font-size: 13px;
  text-transform: capitalize;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  color: #ffffff !important;
  text-align: center;
  padding: 0.75rem;
}
::v-deep tr:nth-child(even) {
  background-color: #f2f2f2;
}
::v-deep tr:nth-child(odd) {
  background-color: #ffffff;
}
.leaderboardZindex {
  z-index: 999;
  position: relative;
  margin-top: -2em;
}
.textBlock {
  z-index: 999;
  position: relative;
}
p.dates {
  color: #fff!important;
  font-size: 15px;
  text-transform: capitalize;
}
h1.shortname {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
}
p.coursename {
  color: #ffffff;
  font-size: 15px;
  /* font: normal normal normal 15px Barlow; */
}
.backgroundImage {
  background-repeat: no-repeat;
  background-size: cover;
}
span.renderedHomeText {
  position: relative;
  z-index: 999;
}
#overMay {
  position: absolute;
  display: block;
  width: 100%;
  height: 689px;
  top: 105px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}
#jumbo {
  transition: transform 3s, filter 3s ease-in-out;
  width: 100%;
  background: transparent linear-gradient(270deg, #ffffff00 0%, #000000 100%) 0%
    0% no-repeat padding-box;
  /* opacity: 0.4; */
  display: block;
  height: 589px;
  position: absolute;
  overflow: hidden;
}
#jumbo:hover {
  /* transform: scale(1.5); */
  transform: scale(1);
}
::v-deep .jumbotron {
  height: 589px;
  padding: 12% 0%;
  color: white;
  margin-bottom: 0px;
  border-radius: 0px;
}
h1 {
  font-size: 32px;
  padding-bottom: 10px;
  font-weight: 600;
  z-index: 999;
  position: relative;
}
.postBtn {
  display: block;
  background: #2c2c2c;
  border: none;
  color: #fff !important;
  padding: 5px 20px;
  width: 200px;
  text-align: center;
  border-radius: 0;
  z-index: 999;
  position: relative;
  line-height: 18px;
  /* height: 35px; */
}
.postBtn:hover {
  background-color: #000;
}

@media only screen and (min-width: 1500px) {
  .leaderboardZindex {
    z-index: 999;
    position: relative;
    margin-top: -9em;
  }
}
@media only screen and (max-width: 990px) {
  .row.pastbtnRow {
    display: block;
  }
  button.btn.pastbtn.btn-secondary {
    border-radius: 0px !important;
  }
  button.btn.pastbtn.btn-secondary.activeBtn {
    border-radius: 0px !important;
  }
  .jumbotron {
    height: auto;
    padding: 12% 0%;
    color: white;
  }
  #jumbotron {
    height: 389px;
  }
  .leaderboardZindex {
    margin-top: 2em;
  }
  #jumbo {
    transition: transform 3s, filter 3s ease-in-out;
    width: 100%;
    background-color: rgba(0, 0, 0.5);
    opacity: 0.4;
    display: block;
    height: 389px;
    position: absolute;
    overflow: hidden;
  }
  .leaderboardPosHome.container {
    position: relative;
    margin-top: -70px;
  }
}
@media only screen and (max-width: 768px) {
  /* .jumbotron {
    height: 389px;
    padding: 12% 0%;
    color: white;
  }
  #jumbotron {
    height: 389px;
  } */
}

@media only screen and (max-width: 425px) {
  .textBlock {
    z-index: 999;
    position: relative;
    /* top: 70px; */
  }
  .tourInfoBtn {
    width: 100%;
  }
}
</style>
