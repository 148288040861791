
<template>
  <div class="footer">
    <div class="SecondBottomSection ">
      <FooterMenu />
    </div>
    <div class="ocs green">
      <b-container class="mobileContainter">
        <b-row>
          <b-col lg="6" md="12" cols="12" class="deskopOnly">
            <p class="mb-0 copyRight">
              © {{new Date().getFullYear()}} Big Easy Tour
            </p>
          </b-col>
          <b-col lg="6" md="12" cols="12"  order-lg-1>
            <b-row>
              <b-col lg="4" md="12" cols="12" class="bigEasyback">
                <a href="https://sunshinetour.com/" target="_blank">
                  <img class="footerLogo" style="margin-top: 13px; width:135px" src="https://updated.sunshinetour.com//wp-content/uploads/2021/04/q-school-visit-sa-open-12.png">
                </a>
              </b-col>
              <b-col lg="4" md="12" cols="12" class="ladiesTourback">
                <a href="https://sunshineladiestour.golf/" target="_blank">
                  <img class="footerLogo" style="margin-top: -3px; width:135px" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2023/01/secondLDSLogo.png">
                </a>
              </b-col>
              <b-col lg="4" md="12" cols="12" class="seniorback">
                <a href="https://seniortour.co.za/" target="_blank">
                  <img class="footerLogo" src="https://updated.sunshinetour.com//wp-content/uploads/2021/04/q-school-visit-sa-open-4.png">
                </a>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6" md="12" cols="12" class="mobileOnly">
            <p class="mb-0 copyRight">
              © {{new Date().getFullYear()}} Big Easy Tour
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
// import Ads from "@/components/ads.vue";
import FooterMenu from "@/components/footerMenu.vue";
export default {
  name: "Footer",
  components: {
    // Ads,
    FooterMenu
  },
  props: ["course", "currentReport", "title", "season", "config"],
  data() {
    return {
      logoImg: "logoImg",
      ocsLogo: "ocsLogo",
      logoRow: "logoRow",
    };
  },
  methods: {
    prev() {
      this.$refs.myCarousel.prev()
    },
    next() {
      this.$refs.myCarousel.next()
    }
  },
};
</script>

<style scoped>
::v-deep .WholeFooterLinks.col-md-12.col-lg-7.col-12 > div > a:hover {
  text-decoration: none;
}
::v-deep .carousel-caption {
  top: 0;
}
::v-deep .carousel-item {
  height: 200px;
}
::v-deep .carousel-control-prev {
  position: absolute;
  top: 80px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
::v-deep .carousel-control-next {
  right: 0;
  top: 80px;
}
::v-deep .carousel-control-prev-icon {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/Left_Arrow_Grey.png);
  z-index: 999;
}
::v-deep .carousel-control-next-icon {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/Right_Arrow_Grey.png);
  z-index: 999;
}
.SecondBottomSection {
  background-color: #000; 
}
.footerLogo {
  width: 100px;
  margin: auto;
  display: block;
  margin-top: 7px;
}
.bigEasyback {
  background-image: url(https://live-smt.ocs-software.com/wp-content/uploads/2023/12/SST.png);
  padding-top: 10px;
  padding-bottom: 10px;
}
.ladiesTourback {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/LAdies_BG.png);
  padding-top: 10px;
  padding-bottom: 10px;
}
.seniorback {
  background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/Senior_BG.png);
  padding-top: 10px;
  padding-bottom: 10px;
}
.footerAds {
  /* margin-bottom: 17.5em; */
}
.ocsLogo {
  width: 60px;
  margin-top: 15px;
  float: right;
}
.mediaOCS {
  float: right;
}
.socialIcons-pad {
  margin-top: 25px!important;
}
img.Social-logos {
  padding-right: 10px;
}
img.socialPad-icons {
  padding-right: 10px;
}
ul.sst-socal-links.d-table.float-md-right {
  position: relative;
  top: 25px;
}
li.socialIcons {
  display: contents;
}
ul.downloadTabs {
  padding-left: 0;
  margin-top: 25px;
}
li.downloadLi {
  display: table-cell;
  padding-right: 15px;
}
img.downloadAPP{
  width: 135px;
  height: 40px;
}
.connectUs {
  margin-right: 3em;
}
.socailIcon {
  font-size: 18px !important;
  color: #fff;
  margin-right: 25px;
}
.connectRight {
  float: right;
  color: #fff;
  margin-top: 7px;
  font-size: 12pt;
}
.bottomMenu {
  position: relative;
  top: 0px;
}
.footerPad-Download {
  padding-bottom: 20px;
}
span.footerSocial {
  display: contents;
  float: right;
}
.indiFooter-social {
  width: 20px;
  max-width: 45px;
}
::v-deep .footLink > a {
  font-size: 9pt;
  padding-left: 0;
}
span.premierPartners {
  padding: 1rem 0 3rem;
}

#insta {
  border-bottom: 1px solid #868686;
  padding: 20px 0;
}
.partners {
  text-align: center;
  display: block;
  color: #6C6C6C;
  padding-top: 20px;
  font-size: 20px;
  padding-bottom: 0.3em;
  line-height: 1.25;
  text-transform: capitalize;
  font-weight: bold;
  /* font-family: Cormorant Garamond; */
}
/* .third > .col:last-child > a > .logoImg {
  width: 75px;
} */
.row.third.logoRow {
  margin-top: 2em;
}
.logoImg {
  width: 100%;
  /* height: 80px; */
  border: 0px solid #f2f2f2;
  margin-top: 40px;
}
.logoImgTwo {
  width: 115px;
}
.logoImgThree {
  width: 100px;
}
.logoImgFour {
  width: 90px;
}
.green {
  background-color: #2c2c2c !important;
  /* padding-top: 20px; */
}
.lightGreen {
  background-color: #fff;
  padding: 30px 0;
}
.logoRow {
  padding-top: 0%;
  padding-bottom: 1%;
  text-align: center;
  margin-right: 0;
}
.copyRight {
  color: #fff;
  font-size: 85%;
  line-height: 5;
}
.media-body {
  flex: inherit;
  padding-top: 15px;
}
.bottomRight {
  font-size: 9pt;
}
.bottomRight > a {
  color: #fff !important;
}
.bottomRight > a:hover {
  color: #000 !important;
}
.ocs {
  /* padding-bottom: 22px;
  padding-top: 6px; */
}
.appImg {
  width: 130px;
  margin-top: 10px;
}
.download {
  color: #fff;
  font-weight: 600;
  font-size: 9pt;
  margin-top: 32px;
  width: 125px;
}
.connect {
  float: right;
  color: #fff;
  margin-top: 11px;
  font-size: 9pt;
}
li {
  list-style-type: none;
}
li > a {
  color: white !important;
}
.menuLink > a {
  font-size: 9pt;
}
.navbar {
  padding: 0.5rem 0;
}
@media only screen and (min-width: 991px) {
  .mobileOnly {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .mobileContainter {
    max-width: 100%;
  }
  .deskopOnly {
    display: none;
  }
  .logoImg {
    width: 100%;
    height: initial;
  }
}
@media only screen and (max-width: 768px) {
  .connect {
    float: left;
  }
  .icon {
    font-size: 26px;
  }
  .mobHide {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .carousel-item {
    height: 140px;
  }
  ::v-deep .carousel-control-prev-icon {
    display: none;
  }
  ::v-deep .carousel-control-next-icon {
    display: none;
  }
  span.premierPartners{
    padding: 0.3em;
  }
}
</style>